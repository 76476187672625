import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MailSenderService } from 'src/app/mail-sender.service';

const require = "Campo richiesto";

@Component({
  selector: 'app-form-cu',
  templateUrl: './form-cu.component.html',
  styleUrls: ['./form-cu.component.scss']
})
export class FormCuComponent implements OnInit {
  @ViewChild('messageArea') messageArea!: ElementRef;
  
  completamentoOperazione = false;
  completo = false;
  errore = false;
  textLength = 0;
  changepIVA = false;
  changepecCu = false;
  infoPersona = this.fb.group({
    nome : ['', Validators.required],
    cognome : ['', Validators.required],
    CF : ['', [Validators.required, Validators.pattern("^[A-Za-z]{6}[0-9]{2}[A-Za-z]{1}[0-9]{2}[A-Za-z]{1}[0-9]{3}[A-Za-z]{1}$")]],
    email : ['', [Validators.required, Validators.email]],
    pIVA : ['', [Validators.minLength(11), Validators.maxLength(11), Validators.pattern("^[0-9]{11}$")]],
    pecCu : [''],
    terms : ['', Validators.requiredTrue]
  });

  userMessaggio = this.fb.group({
    messaggio : ['', [Validators.required, Validators.maxLength(300)]]
  });

  constructor(private fb: FormBuilder, private mailer: MailSenderService) { }

  ngOnInit(): void {
  }

  getEmailError(){
    if(this.infoPersona.get("email")?.hasError("email")){
      return "Formato email non corretto";
    } else if(this.infoPersona.get("email")?.hasError){
      return require;
    }
    return
  }

  getpIVAError(){
    if(this.infoPersona.get("pIVA")?.hasError("required")){
      return require;
    } else if(this.infoPersona.get("pIVA")?.hasError){
      return "Il campo deve contenere esattamente 11 caratteri numerici";
    }
    return
  }

  getRequiredError(){
    return require;
  }

  getCFError(){
    if(this.infoPersona.get("CF")?.hasError("required")){
      return require;
    } else if(this.infoPersona.get("CF")?.hasError){
      return "Formato non valido"
    }
    return
  }

  setpecCuRequired(){
    if(this.infoPersona.get("pIVA")?.value == "" && this.changepecCu){
      this.infoPersona.controls["pecCu"].clearValidators();
      this.infoPersona.controls["pecCu"].updateValueAndValidity();
      this.changepecCu = false;
    } else if(this.infoPersona.get("pIVA")?.value != "" &&!this.changepecCu){
      this.infoPersona.controls["pecCu"].setValidators([Validators.required]);
      this.infoPersona.controls["pecCu"].updateValueAndValidity();
      this.changepecCu = true;
    }
  }

  setpIVARequired(){
    if(this.infoPersona.get("pecCu")?.value == "" && this.changepIVA){
      this.infoPersona.controls["pIVA"].setValidators(Validators.pattern("^[0-9]{11}$"));
      this.infoPersona.controls["pIVA"].updateValueAndValidity();
      this.changepIVA = false;
    } else if(this.infoPersona.get("pecCu")?.value != "" &&!this.changepIVA){
      this.infoPersona.controls["pIVA"].setValidators([Validators.pattern("^[0-9]{11}$"), Validators.required]);
      this.infoPersona.controls["pIVA"].updateValueAndValidity();
      this.changepIVA = true;
    }
  }

  termsVisible(){
    if(this.infoPersona.get("terms")?.hasError("required")){
      document.getElementById("termini")?.setAttribute("style", "visibility: visible");
    }
  }

  termsHidden(){
    document.getElementById("termini")?.setAttribute("style", "visibility: hidden");
    this.infoPersona.get("terms")?.touched
  }

  lengthCounter(){
    var messageLength = this.messageArea.nativeElement.value.length;
    this.textLength = messageLength;
  }

  mailInformation(){
    if (this.infoPersona.invalid || this.userMessaggio.invalid){
      return
    }
    this.completamentoOperazione = true;  
    var data = JSON.stringify({"nome": this.infoPersona.get("nome")?.value,
                              "cognome": this.infoPersona.get("cognome")?.value,
                              "CF": this.infoPersona.get("CF")?.value,
                              "email": this.infoPersona.get("email")?.value,
                              "pIVA": this.infoPersona.get("pIVA")?.value,
                              "pecCu": this.infoPersona.get("pecCu")?.value,
                              "messaggio": this.userMessaggio.get("messaggio")?.value,
                              });                  
    var response = this.mailer.informazioni(data);
    response.subscribe(x => {
      this.resultControl(x)
    },
    error => {
      this.completo = true;
      this.errore = true;
      console.log(error);
    })                   
  }

  resultControl(x : Object){
    if(x == "inviato"){
      this.completamentoOperazione = true;
      this.completo = true;
    } else {
      this.completo = true;
      this.errore = true;
      console.log(x);
    }
  }
}
