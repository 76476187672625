import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-e-big',
  templateUrl: './e-big.component.html',
  styleUrls: ['./e-big.component.scss'],
})
export class EBigComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    window.scrollTo(0, 0);
  }
}
