import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-e-voke',
  templateUrl: './e-voke.component.html',
  styleUrls: ['./e-voke.component.scss'],
})
export class EVokeComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    window.scrollTo(0, 0);
  }
}
