<div
  class="data-form-container container"
  data-aos="slide-down"
  data-aos-duration="1000"
>
  <h1 class="title-form" i18n>Inserisci i tuoi dati</h1>
  <mat-vertical-stepper linear #stepper>
    <mat-step [stepControl]="datiPersona" color="accent">
      <form [formGroup]="datiPersona" (ngSubmit)="termsVisible()">
        <ng-template matStepLabel
          ><span i18n>Informazioni d'identità</span></ng-template
        >
        <mat-form-field appearance="outline" class="form-input" color="accent">
          <mat-label i18n>Nome</mat-label>
          <input
            formControlName="nome"
            matInput
            placeholder="Es: Carlo, Mario, etc."
            required
            name="Nome"
          />
          <mat-error *ngIf="!datiPersona.get('nome')?.valid">{{
            getRequiredError()
          }}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="form-input" color="accent">
          <mat-label i18n>Cognome</mat-label>
          <input
            formControlName="cognome"
            matInput
            required
            placeholder="Es: Bianchi, Rossi, etc."
            name="Cognome"
          />
          <mat-error *ngIf="!datiPersona.get('cognome')?.valid">{{
            getRequiredError()
          }}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="form-input" color="accent">
          <mat-label i18n>C.F.</mat-label>
          <input
            formControlName="CF"
            matInput
            required
            placeholder="Codice Fiscale"
            name="CodFisc"
          />
          <mat-error *ngIf="!datiPersona.get('CF')?.valid">{{
            getRequiredError()
          }}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="form-input" color="accent">
          <mat-label i18n>E-mail</mat-label>
          <input
            formControlName="email"
            matInput
            required
            placeholder="es: nomeutente@dominio.it"
            name="E-mail"
          />
          <mat-error *ngIf="!datiPersona.get('email')?.valid">{{
            getEmailError()
          }}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="form-input" color="accent">
          <mat-label i18n>Tipo documento</mat-label>
          <mat-select
            formControlName="documentType"
            matInput
            required
            placeholder="Tipo documento"
            name="documentType"
          >
            <mat-option value="CI" i18n>Carta d'identità</mat-option>
            <mat-option value="license" i18n>Patente</mat-option>
            <mat-option value="passport" i18n>Passaporto</mat-option>
          </mat-select>
          <mat-error *ngIf="!datiPersona.get('pIVA')?.valid">{{
            getdocumentTypeError()
          }}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="form-input" color="accent">
          <mat-label i18n>Numero documento</mat-label>
          <input
            formControlName="documentNumber"
            matInput
            required
            placeholder="Numero documento"
            id="documentNumber"
            name="documentNumber"
          />
          <mat-error *ngIf="!datiPersona.get('pecCu')?.valid">{{
            getRequiredError()
          }}</mat-error>
        </mat-form-field>
        <div class="mat-typography" style="width: 80%" id="terms">
          <mat-checkbox
            formControlName="terms"
            color="accent"
            (click)="termsHidden()"
            class="col-md-4"
          >
            <span i18n>
              Accetto
              <a class="bg-white" target="_blank" href="./privacy.html"
                >termini e condizioni</a
              >
            </span>
          </mat-checkbox>
          <br />
          <span id="termini" color="warn" style="visibility: hidden" i18n
            >Devi accettare i termini di servizio per continuare</span
          >
        </div>
        <p style="position: relative; height: 36px">
          <button
            mat-raised-button
            matStepperNext
            color="accent"
            class="next-btn"
          >
            Next
          </button>
        </p>
      </form>
    </mat-step>
    <mat-step [stepControl]="datiDomicilio" color="accent">
      <form [formGroup]="datiDomicilio" class="mat-typography">
        <ng-template matStepLabel
          ><span i18n>Informazioni sul noleggio</span></ng-template
        >
        <mat-form-field appearance="outline" class="form-input" color="accent">
          <mat-label i18n>Nome struttura</mat-label>
          <input
            formControlName="structure"
            matInput
            required
            placeholder="Nome struttura"
            name="structure"
          />
          <mat-error *ngIf="!datiDomicilio.get('structure')?.valid">{{
            getRequiredError()
          }}</mat-error>
        </mat-form-field>
        <mat-form-field
          appearance="outline"
          class="form-input-3"
          color="accent"
        >
          <mat-label i18n>Scegli una data</mat-label>
          <input
            matInput
            [min]="minDate"
            [matDatepicker]="picker"
            required
            formControlName="date"
          />
          <mat-hint>DD/MM/YYYY</mat-hint>
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <mat-error *ngIf="!datiDomicilio.get('date')?.valid">{{
            getRequiredError()
          }}</mat-error>
        </mat-form-field>
        <mat-form-field
          appearance="outline"
          class="form-input-3"
          color="accent"
        >
          <mat-label i18n>Fascia oraria</mat-label>
          <mat-select
            formControlName="time"
            matInput
            required
            placeholder="Fascia oraria"
            name="time"
          >
            <mat-option value="fullday" i18n
              >Intera giornata (09:00-19:00)</mat-option
            >
            <mat-option value="license" i18n>Mattina (09:00-13:00)</mat-option>
            <mat-option value="passport" i18n
              >Pomeriggio (15:00-19:00)</mat-option
            >
          </mat-select>
          <mat-error *ngIf="!datiDomicilio.get('time')?.valid">{{
            getRequiredError()
          }}</mat-error>
        </mat-form-field>
        <p style="position: relative; height: 36px">
          <button
            mat-raised-button
            matStepperNext
            color="accent"
            class="next-btn"
          >
            Next
          </button>
          <button
            mat-raised-button
            matStepperPrevious
            color="accent"
            class="back-btn"
          >
            Back
          </button>
        </p>
      </form>
    </mat-step>
    <mat-step color="accent">
      <ng-template matStepLabel i18n><span>CheckOut</span></ng-template>
      <div class="container mat-tipography">
        <table mat-table>
          <tr>
            <th mat-header-cell i18n>Riepilogo informazioni</th>
          </tr>
          <tr>
            <td mat-cell i18n>Nome</td>
            <td mat-cell>{{ datiPersona.get("nome")?.value }}</td>
          </tr>
          <tr>
            <td mat-cell i18n>Cognome</td>
            <td mat-cell>{{ datiPersona.get("cognome")?.value }}</td>
          </tr>
          <tr>
            <td mat-cell i18n>C.F.</td>
            <td mat-cell>{{ datiPersona.get("CF")?.value }}</td>
          </tr>
          <tr>
            <td mat-cell i18n>Email</td>
            <td mat-cell>{{ datiPersona.get("email")?.value }}</td>
          </tr>
          <tr>
            <td mat-cell i18n>Tipo documento</td>
            <td mat-cell>{{ datiPersona.get("documentType")?.value }}</td>
          </tr>
          <tr>
            <td mat-cell i18n>Numero documento</td>
            <td mat-cell>{{ datiPersona.get("documentNumber")?.value }}</td>
          </tr>
          <tr>
            <td mat-cell i18n>E-big</td>
            <td mat-cell>{{ datiDomicilio.get("e_big")?.value }}</td>
          </tr>
          <tr>
            <td mat-cell i18n>Travel cross</td>
            <td mat-cell>{{ datiDomicilio.get("travel")?.value }}</td>
          </tr>
          <tr>
            <td mat-cell i18n>Urban bike icon</td>
            <td mat-cell>{{ datiDomicilio.get("urban")?.value }}</td>
          </tr>
          <tr>
            <td mat-cell i18n>Appia</td>
            <td mat-cell>{{ datiDomicilio.get("appia")?.value }}</td>
          </tr>
          <tr>
            <td mat-cell i18n>casco L</td>
            <td mat-cell>{{ datiAccessori.get("cascoL")?.value }}</td>
          </tr>
          <tr>
            <td mat-cell i18n>Casco M</td>
            <td mat-cell>{{ datiAccessori.get("cascoM")?.value }}</td>
          </tr>
          <tr>
            <td mat-cell i18n>Seggiolino</td>
            <td mat-cell>{{ datiAccessori.get("seggiolino")?.value }}</td>
          </tr>
        </table>
      </div>
      <div class="linea-divisoria"></div>
      <div class="centered-content">
        <p i18n>
          Confermare richiesta di prenotazione: la contatteremo al più presto
          per l'eventuale conferma
        </p>
        <p class="send-btn">
          <button
            mat-raised-button
            color="accent"
            (click)="mailPrenotazione()"
            i18n
          >
            Invia
          </button>
        </p>
      </div>
      <p style="position: relative; height: 36px">
        <button
          mat-raised-button
          matStepperPrevious
          color="accent"
          class="send-btn"
        >
          Back
        </button>
      </p>
    </mat-step>
  </mat-vertical-stepper>
</div>
<span *ngIf="completamentoOperazione"
  ><app-loading-result
    [completo]="completo"
    [errore]="errore"
  ></app-loading-result
></span>
