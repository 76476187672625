<div class="tutorial-page-bg">
  <div class="container mat-typography" style="text-align: center">
    <h1 class="title boa" style="margin-top: 30px" i18n>
      Tutorial Noleggio E-bike e Monopattini
    </h1>
    <div class="video">
      <iframe
        class="youtube"
        [src]="safeUrl"
        frameborder="0"
        allowfullscreen
      ></iframe>
    </div>
  </div>
</div>
