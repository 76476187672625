import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-renters-page',
  templateUrl: './renters-page.component.html',
  styleUrls: ['./renters-page.component.scss'],
})
export class RentersPageComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  scrollToTop(id: string) {
    window.scrollTo(0, 0);
    let active = document.getElementsByClassName('active');
    active?.item(0)?.classList.remove('active');
    let newActive = document.getElementById(id);
    newActive?.classList.add('active');
  }
}
