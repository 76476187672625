import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MailSenderService } from 'src/app/mail-sender.service';

const require = $localize`Campo richiesto`;

@Component({
  selector: 'app-buy-form',
  templateUrl: './buy-form.component.html',
  styleUrls: ['./buy-form.component.scss'],
})
export class BuyFormComponent implements OnInit {
  minDate = new Date();
  maxDate = new Date(
    this.minDate.getFullYear(),
    this.minDate.getMonth(),
    this.minDate.getDay() + 9
  );
  qBikes = 0;

  completamentoOperazione = false;
  completo = false;
  errore = false;
  firstTime = true;
  changepIVA = false;
  changepecCu = false;
  isLinear = true;
  datiPersona = this.fb.group({
    nome: ['', Validators.required],
    cognome: ['', Validators.required],
    CF: ['', [Validators.required, Validators.minLength(5)]],
    email: ['', [Validators.required, Validators.email]],
    documentNumber: ['', [Validators.required, Validators.minLength(4)]],
    documentType: ['', Validators.required],
    countryCode: ['', Validators.required],
    phone: [
      '',
      [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern('^[0-9]{8,11}'),
      ],
    ],
    terms: ['', Validators.requiredTrue],
  });

  datiDomicilio = this.fb.group({
    structure: ['', Validators.required],
    date: ['', Validators.required],
    time: ['', Validators.required],
    e_big: [0, Validators.min(1)],
    urban: [0, Validators.min(1)],
    travel: [0, Validators.min(1)],
    appia: [0, Validators.min(1)],
  });

  datiAccessori = this.fb.group({
    seggiolino: [0],
    cascoL: [0],
    cascoM: [0],
  });

  constructor(private fb: FormBuilder, private mailer: MailSenderService) {}

  ngOnInit() {}

  dataCreation() {
    var data = JSON.stringify({
      nome: this.datiPersona.get('nome')?.value,
      cognome: this.datiPersona.get('cognome')?.value,
      CF: this.datiPersona.get('CF')?.value,
      email: this.datiPersona.get('email')?.value,
      dType: this.datiPersona.get('documentType')?.value,
      dNumber: this.datiPersona.get('documentNumber')?.value,
      phone:
        '+' +
        this.datiPersona.get('countryCode')?.value +
        ' ' +
        this.datiPersona.get('phone')?.value,
      structure: this.datiDomicilio.get('structure')?.value,
      date: this.datiDomicilio.get('date')?.value,
      time: this.datiDomicilio.get('time')?.value,
      e_big: this.datiDomicilio.get('e_big')?.value,
      travel: this.datiDomicilio.get('travel')?.value,
      urban: this.datiDomicilio.get('urban')?.value,
      appia: this.datiDomicilio.get('appia')?.value,
      cascoL: this.datiAccessori.get('cascoL')?.value,
      cascoM: this.datiAccessori.get('cascoM')?.value,
      seggiolino: this.datiAccessori.get('seggiolino')?.value,
    });
    return data;
  }

  getEmailError() {
    if (this.datiPersona.get('email')?.hasError('email')) {
      return $localize`Formato email non corretto`;
    } else if (this.datiPersona.get('email')?.hasError) {
      return require;
    }
    return;
  }

  getdocumentTypeError() {
    if (this.datiPersona.get('documentType')?.hasError('required')) {
      return require;
    } else if (this.datiPersona.get('documentType')?.hasError) {
      return $localize`Il campo deve contenere esattamente 11 caratteri numerici`;
    }
    return;
  }

  getCAPError() {
    if (this.datiDomicilio.get('CAP')?.hasError('pattern')) {
      return $localize`Formato errato`;
    } else {
      return require;
    }
  }

  getRequiredError() {
    return require;
  }

  getCFError() {
    if (this.datiPersona.get('CF')?.hasError('required')) {
      return require;
    } else if (this.datiPersona.get('CF')?.hasError) {
      return $localize`Formato non valido`;
    }
    return;
  }

  termsVisible() {
    if (this.datiPersona.get('terms')?.hasError('required')) {
      document
        .getElementById('termini')
        ?.setAttribute('style', 'visibility: visible');
    }
  }

  termsHidden() {
    document
      .getElementById('termini')
      ?.setAttribute('style', 'visibility: hidden');
    this.datiPersona.get('terms')?.touched;
  }

  mailPrenotazione() {
    this.completamentoOperazione = true;
    var response = this.mailer.prenotazione(this.dataCreation());
    response.subscribe(
      (x) => {
        this.resultControl(x);
      },
      (error) => {
        this.completo = true;
        this.errore = true;
        console.log(error);
      }
    );
  }

  resultControl(x: Object) {
    if (x == 'inviato') {
      this.completamentoOperazione = true;
      this.completo = true;
    } else {
      this.completo = true;
      this.errore = true;
      console.log(x);
    }
  }

  productValidator() {
    this.qBikes =
      this.datiDomicilio.get('e_big')?.value +
      this.datiDomicilio.get('travel')?.value +
      this.datiDomicilio.get('urban')?.value +
      this.datiDomicilio.get('appia')?.value;
    if (
      this.datiDomicilio.get('e_big')?.value > 0 ||
      this.datiDomicilio.get('travel')?.value > 0 ||
      this.datiDomicilio.get('urban')?.value > 0 ||
      this.datiDomicilio.get('appia')?.value > 0
    ) {
      this.datiDomicilio.controls['e_big'].clearValidators();
      this.datiDomicilio.controls['travel'].clearValidators();
      this.datiDomicilio.controls['urban'].clearValidators();
      this.datiDomicilio.controls['appia'].clearValidators();
      this.datiDomicilio.controls['e_big'].updateValueAndValidity();
      this.datiDomicilio.controls['travel'].updateValueAndValidity();
      this.datiDomicilio.controls['urban'].updateValueAndValidity();
      this.datiDomicilio.controls['appia'].updateValueAndValidity();
    } else {
      this.datiDomicilio.controls['e_big'].setValidators([Validators.min(1)]);
      this.datiDomicilio.controls['travel'].setValidators([Validators.min(1)]);
      this.datiDomicilio.controls['urban'].setValidators([Validators.min(1)]);
      this.datiDomicilio.controls['appia'].setValidators([Validators.min(1)]);
      this.datiDomicilio.controls['e_big'].updateValueAndValidity();
      this.datiDomicilio.controls['travel'].updateValueAndValidity();
      this.datiDomicilio.controls['urban'].updateValueAndValidity();
      this.datiDomicilio.controls['appia'].updateValueAndValidity();
    }
  }
}
