import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-urban-icon',
  templateUrl: './urban-icon.component.html',
  styleUrls: ['./urban-icon.component.scss'],
})
export class UrbanIconComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    window.scrollTo(0, 0);
  }
}
