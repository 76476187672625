import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MailSenderService } from 'src/app/mail-sender.service';
import { CouponService } from 'src/app/coupon.service';

const require = 'Campo richiesto';
const price = 270;

@Component({
  selector: 'app-rent-form',
  templateUrl: './rent-form.component.html',
  styleUrls: ['./rent-form.component.scss'],
})
export class RentFormComponent implements OnInit {
  private prezzo = '0';
  private sconto = 0;
  minDate = new Date();
  maxDate = new Date(
    this.minDate.getFullYear(),
    this.minDate.getMonth(),
    this.minDate.getDay() + 9
  );
  qBikes = 0;

  public payPalConfig?: IPayPalConfig;
  completamentoOperazione = false;
  completo = false;
  errore = false;
  firstTime = true;
  changepIVA = false;
  changepecCu = false;
  isLinear = true;
  datiPersona = this.fb.group({
    nome: ['', Validators.required],
    cognome: ['', Validators.required],
    CF: [
      '',
      [
        Validators.required,
        Validators.pattern(
          '^[A-Za-z]{6}[0-9]{2}[A-Za-z]{1}[0-9]{2}[A-Za-z]{1}[0-9]{3}[A-Za-z]{1}$'
        ),
      ],
    ],
    email: ['', [Validators.required, Validators.email]],
    documentNumber: ['', [Validators.required]],
    documentType: ['', Validators.required],
    terms: ['', Validators.requiredTrue],
  });

  datiDomicilio = this.fb.group({
    structure: ['', Validators.required],
    date: ['', Validators.required],
    time: ['', Validators.required],
    e_big: [0, Validators.min(1)],
    urban: [0, Validators.min(1)],
    travel: [0, Validators.min(1)],
    appia: [0, Validators.min(1)],
  });

  datiAcquisto = this.fb.group({
    tipoCliente: ['1', Validators.required],
    quantità: ['1', [Validators.required, Validators.pattern('^[0-9]{1,3}$')]],
    codRivenditore: ['', Validators.pattern('^[A-Z0-9]{4}$')],
    nomeBarca: [''],
    nomiBarche: [''],
    tipoPagamento: ['1', Validators.required],
  });

  datiAccessori = this.fb.group({
    seggiolino: [0],
    cascoL: [0],
    cascoM: [0],
  });

  constructor(
    private fb: FormBuilder,
    private mailer: MailSenderService,
    private snackbar: MatSnackBar,
    private couponSearch: CouponService
  ) {}

  ngOnInit() {}

  dataCreation() {
    var data = JSON.stringify({
      nome: this.datiPersona.get('nome')?.value,
      cognome: this.datiPersona.get('cognome')?.value,
      CF: this.datiPersona.get('CF')?.value,
      email: this.datiPersona.get('email')?.value,
      dType: this.datiPersona.get('documentType')?.value,
      dNumber: this.datiPersona.get('documentNumber')?.value,
      structure: this.datiDomicilio.get('structure')?.value,
      date: this.datiDomicilio.get('date')?.value,
      time: this.datiDomicilio.get('time')?.value,
      e_big: this.datiDomicilio.get('e_big')?.value,
      travel: this.datiDomicilio.get('travel')?.value,
      urban: this.datiDomicilio.get('urban')?.value,
      appia: this.datiDomicilio.get('appia')?.value,
      cascoL: this.datiAccessori.get('cascoL')?.value,
      cascoM: this.datiAccessori.get('cascoM')?.value,
      seggiolino: this.datiAccessori.get('seggiolino')?.value,
    });
    return data;
  }

  getPrice() {
    let quantità = this.datiAcquisto.get('quantità')?.value;
    this.prezzo = String(quantità * price - this.sconto);
    return this.prezzo;
  }

  getEmailError() {
    if (this.datiPersona.get('email')?.hasError('email')) {
      return 'Formato email non corretto';
    } else if (this.datiPersona.get('email')?.hasError) {
      return require;
    }
    return;
  }

  getdocumentTypeError() {
    if (this.datiPersona.get('documentType')?.hasError('required')) {
      return require;
    } else if (this.datiPersona.get('documentType')?.hasError) {
      return 'Il campo deve contenere esattamente 11 caratteri numerici';
    }
    return;
  }

  getCAPError() {
    if (this.datiDomicilio.get('CAP')?.hasError('pattern')) {
      return 'Formato errato';
    } else {
      return require;
    }
  }

  getRequiredError() {
    return require;
  }

  getCFError() {
    if (this.datiPersona.get('CF')?.hasError('required')) {
      return require;
    } else if (this.datiPersona.get('CF')?.hasError) {
      return 'Formato non valido';
    }
    return;
  }

  getQuantitaError() {
    if (this.datiAcquisto.get('quantità')?.hasError('pattern')) {
      return 'Formato errato';
    } else {
      return require;
    }
  }

  setpecCuRequired() {
    if (this.datiPersona.get('pIVA')?.value == '' && this.changepecCu) {
      this.datiPersona.controls['pecCu'].clearValidators();
      this.datiPersona.controls['pecCu'].updateValueAndValidity();
      this.changepecCu = false;
    } else if (this.datiPersona.get('pIVA')?.value != '' && !this.changepecCu) {
      this.datiPersona.controls['pecCu'].setValidators([Validators.required]);
      this.datiPersona.controls['pecCu'].updateValueAndValidity();
      this.changepecCu = true;
    }
  }

  setpIVARequired() {
    if (this.datiPersona.get('pecCu')?.value == '' && this.changepIVA) {
      this.datiPersona.controls['pIVA'].setValidators(
        Validators.pattern('^[0-9]{11}$')
      );
      this.datiPersona.controls['pIVA'].updateValueAndValidity();
      this.changepIVA = false;
    } else if (this.datiPersona.get('pecCu')?.value != '' && !this.changepIVA) {
      this.datiPersona.controls['pIVA'].setValidators([
        Validators.pattern('^[0-9]{11}$'),
        Validators.required,
      ]);
      this.datiPersona.controls['pIVA'].updateValueAndValidity();
      this.changepIVA = true;
    }
  }

  termsVisible() {
    if (this.datiPersona.get('terms')?.hasError('required')) {
      document
        .getElementById('termini')
        ?.setAttribute('style', 'visibility: visible');
    }
  }

  termsHidden() {
    document
      .getElementById('termini')
      ?.setAttribute('style', 'visibility: hidden');
    this.datiPersona.get('terms')?.touched;
  }

  mailBonifico() {
    this.completamentoOperazione = true;
    var response = this.mailer.bonifico(this.dataCreation());
    response.subscribe(
      (x) => {
        this.resultControl(x);
      },
      (error) => {
        this.completo = true;
        this.errore = true;
        console.log(error);
      }
    );
  }

  mailNoleggiatore() {
    this.completamentoOperazione = true;
    var response = this.mailer.noleggiatore(this.dataCreation());
    response.subscribe(
      (x) => {
        this.resultControl(x);
      },
      (error) => {
        this.completo = true;
        this.errore = true;
        console.log(error);
      }
    );
  }

  mailPrenotazione() {
    var response = this.mailer.prenotazione(this.dataCreation());
    response.subscribe(
      (x) => {
        this.resultControl(x);
      },
      (error) => {
        this.completo = true;
        this.errore = true;
        console.log(error);
      }
    );
  }

  initConfig(): void {
    if (this.sconto) {
      if (this.firstTime) {
        this.firstTime = false;
        this.payPalConfig = {
          currency: 'EUR',
          clientId:
            'Ac9EfD7OZGNXI7P4KbK8clK4jLWakcLK1hIimiKKI2AeG-JDSb0RHvI1oEZJM1vyr16tv-UJUREqWhVU',
          createOrderOnClient: (data) =>
            <ICreateOrderRequest>{
              intent: 'CAPTURE',
              purchase_units: [
                {
                  amount: {
                    currency_code: 'EUR',
                    value: String(price - this.sconto),
                    breakdown: {
                      item_total: {
                        currency_code: 'EUR',
                        value: String(price - this.sconto),
                      },
                    },
                  },
                  items: [
                    {
                      name: 'Dispositivo Bseasat scontato',
                      quantity: '1',
                      category: 'PHYSICAL_GOODS',
                      unit_amount: {
                        currency_code: 'EUR',
                        value: String(price - this.sconto),
                      },
                    },
                  ],
                },
              ],
            },
          advanced: {
            commit: 'true',
          },
          style: {
            label: 'paypal',
            layout: 'vertical',
          },
          onApprove: (data, actions) => {
            this.completamentoOperazione = true;
            actions.order.get().then((details: any) => {});
          },
          onClientAuthorization: (data) => {
            let response = this.mailer.pagamentoCompletato(this.dataCreation());
            response.subscribe(
              (x) => this.resultControl(x),
              (error) => (
                (this.completo = true), (this.errore = true), console.log(error)
              )
            );
          },
          onCancel: (data, actions) => {
            return;
          },
          onError: (err) => {
            this.completo = true;
            this.errore = true;
          },
          onClick: (data, actions) => {
            return;
          },
        };
      } else {
        return;
      }
    } else {
      if (this.firstTime) {
        this.firstTime = false;
        this.payPalConfig = {
          currency: 'EUR',
          clientId:
            'AXbkWn7ZH9-A_HezXLW2OFILiMk1gZmUq39zDifuUg21_DUk1fd1JQYufcV_USWuDDMeCzLwvEvsgDjs',
          createOrderOnClient: (data) =>
            <ICreateOrderRequest>{
              intent: 'CAPTURE',
              purchase_units: [
                {
                  amount: {
                    currency_code: 'EUR',
                    value: String(
                      this.datiAcquisto.get('quantità')?.value * price -
                        this.sconto
                    ),
                    breakdown: {
                      item_total: {
                        currency_code: 'EUR',
                        value: String(
                          this.datiAcquisto.get('quantità')?.value * price -
                            this.sconto
                        ),
                      },
                      discount: {
                        currency_code: 'EUR',
                        value: String(this.sconto),
                      },
                    },
                  },
                  items: [
                    {
                      name: 'Dispositivo Trattori',
                      quantity: this.datiAcquisto.get('quantità')?.value,
                      category: 'PHYSICAL_GOODS',
                      unit_amount: {
                        currency_code: 'EUR',
                        value: String(price),
                      },
                    },
                  ],
                },
              ],
            },
          advanced: {
            commit: 'true',
          },
          style: {
            label: 'paypal',
            layout: 'vertical',
          },
          onApprove: (data, actions) => {
            this.completamentoOperazione = true;
            actions.order.get().then((details: any) => {});
          },
          onClientAuthorization: (data) => {
            let response = this.mailer.pagamentoCompletato(this.dataCreation());
            response.subscribe(
              (x) => this.resultControl(x),
              (error) => (
                (this.completo = true), (this.errore = true), console.log(error)
              )
            );
          },
          onCancel: (data, actions) => {
            return;
          },
          onError: (err) => {
            this.completo = true;
            this.errore = true;
          },
          onClick: (data, actions) => {
            return;
          },
        };
      } else {
        return;
      }
    }
  }

  resultControl(x: Object) {
    if (x == 'inviato') {
      this.completamentoOperazione = true;
      this.completo = true;
    } else {
      this.completo = true;
      this.errore = true;
      console.log(x);
    }
  }

  productValidator() {
    this.qBikes =
      this.datiDomicilio.get('e_big')?.value +
      this.datiDomicilio.get('travel')?.value +
      this.datiDomicilio.get('urban')?.value +
      this.datiDomicilio.get('appia')?.value;
    if (
      this.datiDomicilio.get('e_big')?.value > 0 ||
      this.datiDomicilio.get('travel')?.value > 0 ||
      this.datiDomicilio.get('urban')?.value > 0 ||
      this.datiDomicilio.get('appia')?.value > 0
    ) {
      this.datiDomicilio.controls['e_big'].clearValidators();
      this.datiDomicilio.controls['travel'].clearValidators();
      this.datiDomicilio.controls['urban'].clearValidators();
      this.datiDomicilio.controls['appia'].clearValidators();
      this.datiDomicilio.controls['e_big'].updateValueAndValidity();
      this.datiDomicilio.controls['travel'].updateValueAndValidity();
      this.datiDomicilio.controls['urban'].updateValueAndValidity();
      this.datiDomicilio.controls['appia'].updateValueAndValidity();
    } else {
      this.datiDomicilio.controls['e_big'].setValidators([Validators.min(1)]);
      this.datiDomicilio.controls['travel'].setValidators([Validators.min(1)]);
      this.datiDomicilio.controls['urban'].setValidators([Validators.min(1)]);
      this.datiDomicilio.controls['appia'].setValidators([Validators.min(1)]);
      this.datiDomicilio.controls['e_big'].updateValueAndValidity();
      this.datiDomicilio.controls['travel'].updateValueAndValidity();
      this.datiDomicilio.controls['urban'].updateValueAndValidity();
      this.datiDomicilio.controls['appia'].updateValueAndValidity();
    }
  }
}
