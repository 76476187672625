<footer class="primary-bg white-text">
  <div class="container">
    <div class="row">
      <div class="footer-info col-md-6">
        <p>E-MAIL: info@rentzeroco2.com</p>
        <p>RGM s.r.l.</p>
        <p style="font-size: small">P.IVA 05047840870</p>
        <p type="tel">Tel: +39 095 5875476</p>
        <p type="tel">Mobile: +39 327 2286861</p>
        <p><br /></p>
        <span
          >Logo's and poster by
          <a
            href="https://www.facebook.com/AroundLaboratorioPubblicitario"
            target="_blank"
            >Around-lab</a
          >
        </span>
        <br />
        <span
          >Photo by
          <a
            href="https://unsplash.com/@himiwaybikes?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
            target="_blank"
            >Himiway Bikes</a
          >
          on <a href="https://unsplash.com" target="_blank">Unsplash</a></span
        >
        <br />
        <span
          >Bike's icons by
          <a
            href="https://www.flaticon.com/authors/smashicons"
            title="bike icons"
            target="_blank"
          >
            Smashicons - Flaticon</a
          >
        </span>
        <br />
        <span
          >Flags's icons by
          <a
            href="https://www.flaticon.com/authors/roundicons"
            title="flag icons"
            target="_blank"
          >
            Roundicons - Flaticon</a
          >
        </span>
        <br />
        <span
          >Childhood stickers by
          <a
            href="https://www.flaticon.com/free-stickers/childhood"
            title="childhood stickers"
            target="_blank"
          >
            kerismaker - Flaticon</a
          >
        </span>
        <br />
        <span
          >People stickers by
          <a
            href="https://www.flaticon.com/free-stickers/people"
            title="people stickers"
            target="_blank"
          >
            Stickers - Flaticon</a
          >
        </span>
        <br />
        <span
          >RentZeroCo2 app animated icons by
          <a
            href="https://lordicon.com/"
            title="app animations"
            target="_blank"
          >
            Lordicon.com</a
          >
        </span>
      </div>
      <div class="footer-social col-md-6">
        <p i18n>Seguici su:</p>
        <a target="_blank">
          <div class="logo-social fb"></div>
        </a>
        <a target="_blank">
          <div class="logo-social insta"></div>
        </a>
      </div>
    </div>
  </div>
</footer>
