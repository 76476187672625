import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class MailSenderService {
  HTTPOptions: Object = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
    responseType: 'text',
  };

  constructor(private http: HttpClient) {}

  public bonifico(data: string) {
    var response = this.http.post('mail_bonifico.php', data, this.HTTPOptions);
    return response;
  }

  public informazioni(data: string) {
    var response = this.http.post('mail_info.php', data, this.HTTPOptions);
    return response;
  }

  public noleggiatore(data: string) {
    var response = this.http.post(
      'mail_noleggiatore.php',
      data,
      this.HTTPOptions
    );
    return response;
  }

  public pagamentoCompletato(data: string) {
    var response = this.http.post(
      'mail_pagamento_carte.php',
      data,
      this.HTTPOptions
    );
    return response;
  }

  public lcn(data: string) {
    var response = this.http.post('mail_lcn.php', data, this.HTTPOptions);
    return response;
  }

  public prenotazione(data: string) {
    var response = this.http.post(
      './mail_prenotazione.php',
      data,
      this.HTTPOptions
    );
    return response;
  }
}
