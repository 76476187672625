<div id="renters">
  <app-header-renters></app-header-renters>
  <div id="main-content" style="overflow: hidden; margin-bottom: 30px">
    <div class="container">
      <div
        class="col-md-12 col-sm-12 text-security-box"
        data-aos="fade-in"
        data-aos-duration="2000"
        data-aos-offset="200"
        style="text-align: center"
      >
        <h1 class="bigger accent-text" i18n>
          Scegli il servizio più adatto alle tue esigenze
        </h1>
        <p class="text-security" i18n>
          <br />Abbiamo sviluppato diverse tipologie di servizi per andare in
          contro a qualsiasi tipo di esigenza.<br />
          <br />
          Scegli quello che più si adatta alle tue.
        </p>
      </div>
      <div class="row" style="margin-top: 40px">
        <div
          class="col-md-6 col-sm-12 text-security-box"
          data-aos="slide-right"
          data-aos-duration="800"
          data-aos-offset="100"
        >
          <h1 class="title-main accent-text" i18n>
            - Noleggio a breve termine
          </h1>
          <h1 class="title-main accent-text" i18n>
            - Noleggio a lungo termine
          </h1>
          <h1 class="title-main accent-text" i18n>
            - Noleggio con piattaforma
          </h1>
        </div>
        <div
          class="col-md-6 col-sm-12 image-business"
          data-aos="fade-in"
          data-aos-duration="1000"
          data-aos-offset="100"
        ></div>
      </div>
      <div class="row" style="margin-top: 80px">
        <div
          class="col-md-3 col-sm-12 card short-rent fixed-height"
          data-aos="fade-in"
          data-aos-duration="1000"
          data-aos-offset="100"
        >
          <h1 class="title-main accent-text title-card" i18n>
            Noleggio a breve termine
          </h1>
          <p class="text-security" i18n>- Noleggio giornaliero</p>
          <p class="text-security" i18n>- Noleggio 3 giorni</p>
          <p class="text-security" i18n>- Noleggio 7 giorni</p>
          <p style="font-size: large" i18n>
            I veicoli ti vengono recapitati e la gestione del noleggio dovrà
            essere gestita manualmente
          </p>
          <a routerLink="/contattaci" class="contact-button">
            <button mat-raised-button (click)="scrollToTop('cu-nav')">
              Contattaci per saperne di più
            </button>
          </a>
        </div>
        <div
          class="col-md-3 col-sm-12 card long-rent fixed-height"
          data-aos="fade-in"
          data-aos-duration="1000"
          data-aos-offset="100"
        >
          <h1 class="title-main accent-text title-card" i18n>
            Noleggio a lungo termine
          </h1>
          <p class="text-security" i18n>- Noleggio mensile</p>
          <p class="text-security" i18n>- Noleggio trimestrale</p>
          <p class="text-security" i18n>- Noleggio semestrale</p>
          <p class="text-security" i18n>- Noleggio annuale</p>
          <p style="font-size: large" i18n>
            I veicoli ti vengono recapitati e la gestione del noleggio dovrà
            essere gestita manualmente, verrà inoltre fornito un account per la
            visualizzazione della posizione dei veicoli
          </p>
          <a routerLink="/contattaci" class="contact-button">
            <button mat-raised-button (click)="scrollToTop('cu-nav')">
              Contattaci per saperne di più
            </button>
          </a>
        </div>
        <div
          class="col-md-3 col-sm-12 card platform fixed-height"
          data-aos="fade-in"
          data-aos-duration="1000"
          data-aos-offset="100"
        >
          <h1 class="title-main accent-text title-card" i18n>
            Noleggio con piattaforma
          </h1>
          <p class="text-security" i18n>- Noleggio trimestrale</p>
          <p class="text-security" i18n>- Noleggio semestrale</p>
          <p class="text-security" i18n>- Noleggio annuale</p>
          <p style="font-size: large" i18n>
            I veicoli ti vengono recapitati e la gestione del noleggio sarà
            completamente automatizzata e gestita tramite l'app RentZeroCo2.
            Inoltre verrai fornito di un account di gestione per monitorare i
            noleggi
          </p>
          <a routerLink="/contattaci" class="contact-button">
            <button mat-raised-button (click)="scrollToTop('cu-nav')">
              Contattaci per saperne di più
            </button>
          </a>
        </div>
        <div
          class="col-md-12 col-sm-12 text-security-box"
          data-aos="fade-in"
          data-aos-duration="2000"
          data-aos-offset="200"
          style="text-align: center"
        >
          <h1 class="bigger accent-text" i18n>Non perdere altro tempo!</h1>
          <div class="row" style="margin-top: 40px">
            <div
              class="col-md-6 col-sm-12 image-report-business"
              data-aos="fade-in"
              data-aos-duration="1000"
              data-aos-offset="100"
            ></div>
            <div
              class="col-md-6 col-sm-12 text-security-box height-400"
              data-aos="slide-left"
              data-aos-duration="800"
              data-aos-offset="-100"
            >
              <p class="text-security text-expansion" i18n>
                Il mercato della mobilità green è in piena espansione ed è il
                futuro della mobilità. <br />
                <br />
                Entra ed inizia a guadagnare anche tu in maniera semplice!
              </p>
            </div>
          </div>
        </div>
        <div
          class="col-md-12 col-sm-12 text-security-box last-button"
          data-aos="fade-in"
          data-aos-duration="2000"
          data-aos-offset="0"
          style="text-align: center"
        >
          <a routerLink="/contattaci">
            <button
              mat-raised-button
              (click)="scrollToTop('cu-nav')"
              class="accent-bg white-text"
              style="font-size: xx-large; height: 60px"
              i18n
            >
              Contattaci
            </button>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
