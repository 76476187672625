<div id="guide" style="overflow: hidden; margin-top: 80px; margin-bottom: 30px">
  <h1 class="title-main" style="text-align: center" i18n>Seleziona l'e-bike</h1>
  <div class="row justify-content-around">
    <a routerLink="/x6+" class="col-md-5 col-sm-12 image-bike1 mt-30"></a>
    <a routerLink="/e-voke" class="col-md-5 col-sm-12 image-bike2 mt-30"></a>
  </div>
  <div class="row justify-content-around">
    <a routerLink="/e-big" class="col-md-5 col-sm-12 image-bike3 mt-30"></a>
    <a routerLink="/urban" class="col-md-5 col-sm-12 image-bike4 mt-30"></a>
  </div>
</div>
