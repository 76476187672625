import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-accomodation-page',
  templateUrl: './accomodation-page.component.html',
  styleUrls: ['./accomodation-page.component.scss'],
})
export class AccomodationPageComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  scrollToTop(id: string) {
    window.scrollTo(0, 0);
    let active = document.getElementsByClassName('active');
    active?.item(0)?.classList.remove('active');
    let newActive = document.getElementById(id);
    newActive?.classList.add('active');
  }
}
