import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Coupon } from './coupon';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CouponService {
  HTTPOptions:Object = {

    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
  }

  constructor(private http: HttpClient) { }

  public searchCoupon(cod_coupon: string) : Observable<Coupon>{
    var response = this.http.post<Coupon>('search_coupon.php', cod_coupon, this.HTTPOptions);
    return response;
  }
}
