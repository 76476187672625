<div
  class="col-md-12 col-sm-12 logo-box"
  data-aos="fade-in"
  data-aos-duration="2000"
  data-aos-offset="100"
></div>
<div
  class="col-md-12 col-sm-12 text-security-box"
  data-aos="fade-in"
  data-aos-duration="2000"
  data-aos-offset="100"
  style="text-align: center"
>
  <h1 class="bigger accent-text" i18n>Servizi per i noleggiatori</h1>
  <p class="text-security" i18n>
    <br />Effettua noleggi utilizzando i nostri veicoli green.<br />
    <br />
    Decidi se gestirli manualmente o automatizza tutto grazie alla nostra
    piattaforma.
  </p>
</div>
<div class="row" style="margin-top: 50px">
  <div
    class="col-md-5 col-sm-12 image-scooter"
    data-aos="slide-right"
    data-aos-duration="1000"
    data-aos-offset="100"
  ></div>
  <div class="col-md-2 col-sm-12"></div>
  <div
    class="col-md-5 col-sm-12 text-security-box"
    data-aos="slide-left"
    data-aos-duration="1000"
    data-aos-offset="100"
  >
    <div class="image-bike"></div>
  </div>
</div>
