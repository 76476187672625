import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomePageComponent } from './home-page/home-page.component';
import { MainContentComponent } from './home-page/main-content/main-content.component';
import { PresentationComponent } from './home-page/presentation/presentation.component';
import { ContactUsPageComponent } from './contact-us-page/contact-us-page.component';
import { CookiePopupComponent } from './cookie-popup/cookie-popup.component';
import { FooterComponent } from './footer/footer.component';
import { LoadingResultComponent } from './loading-result/loading-result.component';
import { ShoppingPageComponent } from './shopping-page/shopping-page.component';
import { BuyFormComponent } from './shopping-page/buy-form/buy-form.component';
import { FormCuComponent } from './contact-us-page/form-cu/form-cu.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatStepperModule } from '@angular/material/stepper';
import { MatRadioModule } from '@angular/material/radio';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NgxPayPalModule } from 'ngx-paypal';
import { HttpClientModule } from '@angular/common/http';
import { BikesListComponent } from './bikes-list/bikes-list.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { RentNowComponent } from './rent-now/rent-now.component';
import { RentFormComponent } from './rent-now/rent-form/rent-form.component';
import { AccomodationPageComponent } from './accomodation-page/accomodation-page.component';
import { RentersPageComponent } from './renters-page/renters-page.component';
import { HeaderComponent } from './accomodation-page/header/header.component';
import { HeaderRentersComponent } from './renters-page/header-renters/header-renters.component';
import { TutorialComponent } from './tutorial/tutorial.component';
import { DownloadAppComponent } from './download-app/download-app.component';
import { GuideComponent } from './guide/guide.component';
import { NiloxX6PlusComponent } from './guide/nilox-x6-plus/nilox-x6-plus.component';
import { EBigComponent } from './guide/e-big/e-big.component';
import { UrbanIconComponent } from './guide/urban-icon/urban-icon.component';
import { EVokeComponent } from './guide/e-voke/e-voke.component';
import { TravelCrossComponent } from './guide/travel-cross/travel-cross.component';
import { ShopComponent } from './shop/shop.component';

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    MainContentComponent,
    PresentationComponent,
    ContactUsPageComponent,
    CookiePopupComponent,
    FooterComponent,
    LoadingResultComponent,
    ShoppingPageComponent,
    BuyFormComponent,
    FormCuComponent,
    BikesListComponent,
    RentNowComponent,
    RentFormComponent,
    AccomodationPageComponent,
    RentersPageComponent,
    HeaderComponent,
    HeaderRentersComponent,
    TutorialComponent,
    DownloadAppComponent,
    GuideComponent,
    NiloxX6PlusComponent,
    EBigComponent,
    UrbanIconComponent,
    EVokeComponent,
    TravelCrossComponent,
    ShopComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
    MatCheckboxModule,
    MatStepperModule,
    MatRadioModule,
    MatSidenavModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatToolbarModule,
    NgxPayPalModule,
    HttpClientModule,
    MatDatepickerModule,
    MatNativeDateModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
