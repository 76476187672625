<div id="main-content" style="overflow: hidden; margin-bottom: 30px">
  <div class="container">
    <div class="row">
      <div
        class="col-md-6 col-sm-12 text-security-box"
        data-aos="slide-right"
        data-aos-duration="800"
        data-aos-offset="100"
      >
        <h1 class="title-main accent-text" i18n>Muoviti liberamente</h1>
        <p class="text-security" i18n>
          <br />Le nostre e-bike fanno bene all'ambiente e alle tue vacanze<br />
        </p>
      </div>
      <div
        class="col-md-6 col-sm-12 image-bike"
        data-aos="fade-in"
        data-aos-duration="1000"
        data-aos-offset="100"
      ></div>
    </div>
    <div class="row">
      <div
        class="col-md-6 col-sm-12 image-group"
        data-aos="fade-in"
        data-aos-duration="1000"
        data-aos-offset="100"
      ></div>
      <div
        class="col-md-6 col-sm-12 text-security-box"
        data-aos="slide-left"
        data-aos-duration="800"
        data-aos-offset="100"
      >
        <h1 class="title-main accent-text" i18n>Adatte a tutti i percorsi</h1>
        <br />
        <p class="text-security" i18n>
          Con le nostre e-bike potrai andare dove vuoi con il minimo sforzo.<br />
        </p>
      </div>
    </div>
  </div>
</div>
