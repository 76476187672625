import { Component } from '@angular/core';
import * as AOS from 'aos';
import 'aos/dist/aos.css';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'bikesat';

  ngOnInit(){
    AOS.init();
  }

  scroll(id: string){
    let active = document.getElementsByClassName("active");
    active?.item(0)?.classList.remove("active");
    let newActive = document.getElementById(id+"-nav");
    newActive?.classList.add("active");
    setTimeout(() => {
      const elmnt = document.getElementById(id);
      elmnt?.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    }, 50);  
  }

  scrollToTop(id: string){
    window.scrollTo(0,0);
    let active = document.getElementsByClassName("active");
    active?.item(0)?.classList.remove("active");
    let newActive = document.getElementById(id);
    newActive?.classList.add("active");
  }
}
