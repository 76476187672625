<div id="guide" style="overflow: hidden; margin-top: 80px; margin-bottom: 30px">
  <div class="row justify-content-around">
    <div class="col-md-5 col-sm-12 image-bike1 mt-30"></div>
  </div>
  <div class="row justify-content-around mt-10">
    <a
      class="btn col-md-6 col-sm-12"
      data-bs-toggle="collapse"
      href="#power"
      role="button"
      aria-expanded="false"
      aria-controls="power"
      style="border: solid"
      i18n
    >
      Accensione e Spegnimento
    </a>
    <div class="collapse" id="power">
      <div class="card card-body">
        <div class="row justify-content-around">
          <div class="col-6 img-container img-power"></div>
          <p class="content" style="font-size: large" i18n>
            Tenere premuto il tasto power mostrato in foto per almeno 3 secondi.
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="row justify-content-around mt-10">
    <a
      class="btn col-md-6 col-sm-12"
      data-bs-toggle="collapse"
      href="#powerLedFront"
      role="button"
      aria-expanded="false"
      aria-controls="powerLedFront"
      style="border: solid"
      i18n
    >
      Accensione e Spegnimento luce anteriore
    </a>
    <div class="collapse" id="powerLedFront">
      <div class="card card-body">
        <div class="row justify-content-around">
          <div class="col-6 img-container img-power-led-front"></div>
          <p class="content" style="font-size: large" i18n>
            Cliccare una volta per accendere. Ad ogni click cambia il tipo di
            lampeggiamento. Per spegnere continuare a cliccare fino allo
            spegnimento.
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="row justify-content-around mt-10">
    <a
      class="btn col-md-6 col-sm-12"
      data-bs-toggle="collapse"
      href="#powerLedBack"
      role="button"
      aria-expanded="false"
      aria-controls="powerLedBack"
      style="border: solid"
      i18n
    >
      Accensione e Spegnimento luce posteriore
    </a>
    <div class="collapse" id="powerLedBack">
      <div class="card card-body">
        <div class="row justify-content-around">
          <div class="col-4 img-container img-power-led-back1"></div>
          <div class="col-4 img-container img-power-led-back2"></div>
          <div class="col-4 img-container img-power-led-back3"></div>
          <p class="content" style="font-size: large" i18n>
            Cliccare una volta per accendere. Ad ogni click cambia il tipo di
            lampeggiamento. Per spegnere continuare a cliccare fino allo
            spegnimento.
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="row justify-content-around mt-10">
    <a
      class="btn col-md-6 col-sm-12"
      data-bs-toggle="collapse"
      href="#assistance"
      role="button"
      aria-expanded="false"
      aria-controls="assistance"
      style="border: solid"
      i18n
    >
      Cambio livello assistenza pedalata
    </a>
    <div class="collapse" id="assistance">
      <div class="card card-body">
        <div class="row justify-content-around">
          <div class="col-6 img-container img-assistance"></div>
          <p class="content" style="font-size: large" i18n>
            Premere i tasti con le freccette su e giù rispettivamente per
            aumentare e diminuire il livello di assistenza del motore dell'ebike
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
