import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rent-now',
  templateUrl: './rent-now.component.html',
  styleUrls: ['./rent-now.component.scss'],
})
export class RentNowComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
