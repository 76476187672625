<div
  class="data-form-container container"
  data-aos="slide-down"
  data-aos-duration="1000"
>
  <h1 class="title-form" i18n>Inserisci i tuoi dati</h1>
  <mat-vertical-stepper linear #stepper>
    <mat-step [stepControl]="infoPersona" color="accent">
      <form [formGroup]="infoPersona" (ngSubmit)="termsVisible()">
        <ng-template matStepLabel
          ><span>Informazioni d'identità</span></ng-template
        >
        <mat-form-field appearance="outline" class="form-input" color="accent">
          <mat-label i18n>Nome</mat-label>
          <input
            formControlName="nome"
            matInput
            placeholder="Es: Carlo, Mario, etc."
            required
            name="Nome"
          />
          <mat-error *ngIf="!infoPersona.get('nome')?.valid">{{
            getRequiredError()
          }}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="form-input" color="accent">
          <mat-label i18n>Cognome</mat-label>
          <input
            formControlName="cognome"
            matInput
            required
            placeholder="Es: Bianchi, Rossi, etc."
            name="Cognome"
          />
          <mat-error *ngIf="!infoPersona.get('cognome')?.valid">{{
            getRequiredError()
          }}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="form-input" color="accent">
          <mat-label i18n>C.F.</mat-label>
          <input
            formControlName="CF"
            matInput
            required
            placeholder="Codice Fiscale"
            name="CodFisc"
          />
          <mat-error *ngIf="!infoPersona.get('CF')?.valid">{{
            getCFError()
          }}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="form-input" color="accent">
          <mat-label i18n>E-mail</mat-label>
          <input
            formControlName="email"
            matInput
            required
            placeholder="es: nomeutente@dominio.it"
            name="E-mail"
          />
          <mat-error *ngIf="!infoPersona.get('email')?.valid">{{
            getEmailError()
          }}</mat-error>
        </mat-form-field>
        <p class="mat-typography" id="terms">
          <mat-checkbox
            formControlName="terms"
            color="accent"
            (click)="termsHidden()"
          >
            <span i18n>
              Accetto
              <a class="bg-white" target="_blank" href="./privacy.html"
                >termini e condizioni</a
              >
            </span>
          </mat-checkbox>
          <br />
          <span id="termini" color="warn" style="visibility: hidden"
            >Devi accettare i termini di servizio per continuare</span
          >
        </p>
        <p style="position: relative; height: 36px">
          <button
            mat-raised-button
            matStepperNext
            color="accent"
            class="next-btn"
          >
            Next
          </button>
        </p>
      </form>
    </mat-step>
    <mat-step [stepControl]="userMessaggio" color="accent">
      <form [formGroup]="userMessaggio">
        <ng-template matStepLabel><span>Messaggio</span></ng-template>
        <mat-form-field
          appearance="outline"
          class="message-area"
          color="accent"
        >
          <mat-label i18n>Messaggio</mat-label>
          <textarea
            required
            formControlName="messaggio"
            #messageArea
            rows="7"
            matInput
            maxlength="300"
            (keyup)="lengthCounter()"
          >
          </textarea>
          <div id="the-count">
            <span>{{ textLength }}</span>
            <span id="maximum">/300</span>
          </div>
        </mat-form-field>
        <p class="send-btn">
          <button
            mat-raised-button
            matStepperNext
            color="accent"
            (click)="mailInformation()"
          >
            Invia
          </button>
        </p>
      </form>
    </mat-step>
  </mat-vertical-stepper>
</div>
<span *ngIf="completamentoOperazione"
  ><app-loading-result
    [completo]="completo"
    [errore]="errore"
  ></app-loading-result
></span>
