<div id="shop" style="margin-top: 120px">
  <h1 style="text-align: center">SHOP</h1>
  <div class="row">
    <div
      class="col-md-6 col-sm-12 image-bike-travel"
      data-aos="fade-in"
      data-aos-duration="1000"
    ></div>
    <div
      class="col-md-6 col-sm-12 text-security-box"
      data-aos="slide-left"
      data-aos-duration="1000"
    >
      <h1 class="title-main accent-text">Travel Cross</h1>
      <h1>2000,00 €</h1>
    </div>
    <div
      class="col-md-6 col-sm-12 image-bike-urban"
      data-aos="fade-in"
      data-aos-duration="1000"
    ></div>
    <div
      class="col-md-6 col-sm-12 text-security-box"
      data-aos="slide-left"
      data-aos-duration="1000"
    >
      <h1 class="title-main accent-text">Urban Bike Icon</h1>
      <h1>2500,00 €</h1>
    </div>
  </div>
</div>
