<mat-toolbar
  color="primary"
  data-aos="slide-down"
  data-aos-duration="1000"
  class="toolbar"
>
  <div class="nav-menu">
    <ul id="menu-elements" class="menu-desktop">
      <li id="home-nav" class="active text">
        <a routerLink="" (click)="scroll('home')">Home</a>
      </li>
      <li id="bikes-list-nav" class="text">
        <a routerLink="/bikes-list" (click)="scrollToTop('bikes-list-nav')" i18n
          >Le nostre biciclette</a
        >
      </li>
      <li id="accomodation" class="text">
        <a routerLink="/accomodation" (click)="scrollToTop('accomodation')" i18n
          >Servizi Strutture Ricettive</a
        >
      </li>
      <li id="renters-nav" class="text">
        <a routerLink="/renters" (click)="scrollToTop('renters-nav')" i18n
          >Servizi Noleggiatori</a
        >
      </li>
      <li id="cu-nav" class="text">
        <a routerLink="/contattaci" (click)="scrollToTop('cu-nav')" i18n
          >Contattaci</a
        >
      </li>
      <li id="da-nav" class="text">
        <a routerLink="/download" (click)="scrollToTop('da-nav')" i18n
          >Download APP</a
        >
      </li>
      <li>
        <a routerLink="/shopping"
          ><button
            mat-raised-button
            color="accent"
            style="width: 80px; top: -3px; font-size: large"
            (click)="scrollToTop('')"
          >
            <span
              style="font-family: 'GLSNECB'; position: relative; left: -4px"
              i18n
              >Prenota</span
            >
          </button></a
        >
      </li>
      <li id="shop-nav" class="text">
        <a routerLink="/shop" (click)="scrollToTop('shop-nav')">Shop</a>
      </li>
      <li id="tutorial-nav" class="text">
        <a routerLink="/tutorial" (click)="scrollToTop('tutorial-nav')" i18n
          >Tutorial</a
        >
      </li>
      <li id="guide-nav" class="text">
        <a routerLink="/guide" (click)="scrollToTop('guide-nav')" i18n>Guide</a>
      </li>
      <!--<li>
        <a routerLink="/rent-now"
          ><button
            mat-raised-button
            color="accent"
            style="width: 80px; top: -3px; font-size: large"
            (click)="scrollToTop('')"
          >
            <span
              style="font-family: 'GLSNECB'; position: relative; left: -10px"
              i18n
              >Noleggia</span
            >
          </button></a
        >
      </li>-->
      <li>
        <a href="https://rentzeroco2.com/it">
          <div class="flag italy"></div>
        </a>
      </li>
      <li>
        <a href="https://rentzeroco2.com/en">
          <div class="flag england"></div>
        </a>
      </li>
      <li>
        <a href="https://rentzeroco2.com/fr">
          <div class="flag france"></div>
        </a>
      </li>
      <li>
        <a href="https://rentzeroco2.com/de">
          <div class="flag germany"></div>
        </a>
      </li>
      <li>
        <a href="https://rentzeroco2.com/es">
          <div class="flag spain"></div>
        </a>
      </li>
    </ul>
    <button mat-icon-button (click)="snav.toggle()" class="menu-mobile">
      <mat-icon>menu</mat-icon>
    </button>
  </div>
</mat-toolbar>
<mat-sidenav-container>
  <mat-sidenav #snav class="sidenav" position="end" [fixedInViewport]="true">
    <mat-nav-list style="margin-top: 80px" (click)="snav.toggle()">
      <a mat-list-item routerLink="" (click)="scroll('home')">Home</a>
      <a
        mat-list-item
        routerLink="/bikes-list"
        (click)="scrollToTop('bikes-list-nav')"
        i18n
        >Biciclette</a
      >
      <a
        mat-list-item
        routerLink="/accomodation"
        (click)="scrollToTop('accomodation-nav')"
        i18n
        >Strutture Ricettive</a
      >
      <a
        mat-list-item
        routerLink="/renters"
        (click)="scrollToTop('renters-nav')"
        i18n
        >Noleggiatori</a
      >
      <a
        mat-list-item
        routerLink="/contattaci"
        (click)="scrollToTop('cu-nav')"
        i18n
        >Contattaci</a
      >
      <a
        mat-list-item
        routerLink="/download"
        (click)="scrollToTop('da-nav')"
        i18n
        >Download APP</a
      >
      <a mat-list-item routerLink="/shopping"
        ><button mat-raised-button color="accent" (click)="scrollToTop('')">
          <span style="font-family: 'GLSNECB'; position: relative" i18n
            >Prenota</span
          >
        </button></a
      >
      <a mat-list-item routerLink="/shop" (click)="scrollToTop('shop-nav')"
        >Shop</a
      >
      <a
        mat-list-item
        routerLink="/tutorial"
        (click)="scrollToTop('tutorial-nav')"
        i18n
        >Tutorial</a
      >
      <a
        mat-list-item
        routerLink="/guide"
        (click)="scrollToTop('guide-nav')"
        i18n
        >Guide</a
      >
      <!--<a routerLink="/rent-now" mat-list-item
        ><button mat-raised-button color="accent" (click)="scrollToTop('')">
          <span style="font-family: 'GLSNECB'; position: relative" i18n
            >Noleggia</span
          >
        </button></a
      >-->
      <a href="https://rentzeroco2.com/it" mat-list-item>
        <div class="flag italy"></div>
      </a>
      <a href="https://rentzeroco2.com/en" mat-list-item>
        <div class="flag england"></div>
      </a>
      <a href="https://rentzeroco2.com/fr" mat-list-item>
        <div class="flag france"></div>
      </a>
      <a href="https://rentzeroco2.com/de" mat-list-item>
        <div class="flag germany"></div>
      </a>
      <a href="https://rentzeroco2.com/es" mat-list-item>
        <div class="flag spain"></div>
      </a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <router-outlet></router-outlet>
    <app-footer></app-footer>
    <app-cookie-popup></app-cookie-popup>
  </mat-sidenav-content>
</mat-sidenav-container>
