import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-tutorial',
  templateUrl: './tutorial.component.html',
  styleUrls: ['./tutorial.component.scss'],
})
export class TutorialComponent implements OnInit {
  private it = 'https://www.youtube.com/embed/u5gW442W31k';
  private en = 'https://www.youtube.com/embed/rhNmvVnmgH0';
  private fr = 'https://www.youtube.com/embed/hABf8MXVuJQ';
  private de = 'https://www.youtube.com/embed/Y9pwfnfOOTM';
  private es = 'https://www.youtube.com/embed/gpeAB7cilCU';
  public language = navigator.language;
  safeUrl: any;

  constructor(private _sanitizer: DomSanitizer) {
    let prova = this.language.split('-');
    switch (prova[0]) {
      case 'it':
        this.safeUrl = this._sanitizer.bypassSecurityTrustResourceUrl(this.it);
        break;
      case 'en':
        this.safeUrl = this._sanitizer.bypassSecurityTrustResourceUrl(this.en);
        break;
      case 'fr':
        this.safeUrl = this._sanitizer.bypassSecurityTrustResourceUrl(this.fr);
        break;
      case 'de':
        this.safeUrl = this._sanitizer.bypassSecurityTrustResourceUrl(this.de);
        break;
      case 'es':
        this.safeUrl = this._sanitizer.bypassSecurityTrustResourceUrl(this.es);
        break;

      default:
        this.safeUrl = this._sanitizer.bypassSecurityTrustResourceUrl(this.en);
        break;
    }
  }

  ngOnInit(): void {}
}
