<div class="download-app-page-bg">
  <div class="container mat-typography" style="text-align: center">
    <h1 class="title boa" style="margin-top: 30px" i18n>Download APP</h1>
    <div class="row justify-content-around">
      <a
        href="https://apps.apple.com/it/app/rentzeroco2/id6447355432?l=en"
        class="col-md-5 col-sm-12 app-store"
      >
      </a>
      <a
        href="https://play.google.com/store/apps/details?id=com.rgm.RentZeroCO2"
        class="col-md-5 col-sm-12 play-store"
      ></a>
    </div>
  </div>
</div>
