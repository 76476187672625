<div class="background-black-trasparent pagina-intera">
    <div class="container box-avvisi" style="text-align: center;">
        <span *ngIf="!completo">
            <mat-spinner class="loading-spinner"></mat-spinner>
            <p style="margin-top: 150px;" i18n>Completamento operazione in corso</p>
        </span>
        <span *ngIf="completo && !errore">
            <img src="./assets/images/completato.gif" alt="completato" class="completato">
            <p style="margin-top: 100px;" i18n>Operazione completata</p>
            <br>
            <button mat-raised-button color="primary" routerLink="" i18n>Torna alla home</button>
        </span>
        <span *ngIf="completo && errore">
            <img src="./assets/images/cancel.png" alt="completato" class="completato">
            <p style="margin-top: 100px;" i18n>Operazione fallita, se il problema persiste contattateci all'indirizzo info@rentzeroco2.com</p>
            <br>
            <button mat-raised-button color="primary" routerLink="" i18n>Torna alla home</button>
        </span>
    </div>
</div>
