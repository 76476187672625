import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BikesListComponent } from './bikes-list/bikes-list.component';
import { ContactUsPageComponent } from './contact-us-page/contact-us-page.component';
import { HomePageComponent } from './home-page/home-page.component';
import { RentNowComponent } from './rent-now/rent-now.component';
import { ShoppingPageComponent } from './shopping-page/shopping-page.component';
import { AccomodationPageComponent } from './accomodation-page/accomodation-page.component';
import { RentersPageComponent } from './renters-page/renters-page.component';
import { TutorialComponent } from './tutorial/tutorial.component';
import { DownloadAppComponent } from './download-app/download-app.component';
import { GuideComponent } from './guide/guide.component';
import { EBigComponent } from './guide/e-big/e-big.component';
import { NiloxX6PlusComponent } from './guide/nilox-x6-plus/nilox-x6-plus.component';
import { EVokeComponent } from './guide/e-voke/e-voke.component';
import { UrbanIconComponent } from './guide/urban-icon/urban-icon.component';
import { TravelCrossComponent } from './guide/travel-cross/travel-cross.component';
import { ShopComponent } from './shop/shop.component';

const routes: Routes = [
  { path: '', component: HomePageComponent, pathMatch: 'full' },
  { path: 'shopping', component: ShoppingPageComponent },
  { path: 'contattaci', component: ContactUsPageComponent },
  { path: 'bikes-list', component: BikesListComponent },
  { path: 'rent-now', component: RentNowComponent },
  { path: 'accomodation', component: AccomodationPageComponent },
  { path: 'renters', component: RentersPageComponent },
  { path: 'tutorial', component: TutorialComponent },
  { path: 'download', component: DownloadAppComponent },
  { path: 'guide', component: GuideComponent },
  { path: 'e-big', component: EBigComponent },
  { path: 'x6+', component: NiloxX6PlusComponent },
  { path: 'e-voke', component: EVokeComponent },
  { path: 'urban', component: UrbanIconComponent },
  { path: 'travel-cross', component: TravelCrossComponent },
  { path: 'shop', component: ShopComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
