<div id="bikes-list" style="overflow: hidden; margin-bottom: 30px">
  <div class="row">
    <div
      class="col-md-6 col-sm-12 image-bike1"
      data-aos="fade-in"
      data-aos-duration="1000"
    ></div>
    <div
      class="col-md-6 col-sm-12 text-security-box"
      data-aos="slide-left"
      data-aos-duration="1000"
    >
      <h1 class="title-main accent-text mtb">e-big</h1>
      <div class="row mt-30">
        <div class="icon-frame col-2"></div>
        <h3 class="col-10">
          <span i18n>Telaio: Alluminio</span>
        </h3>
      </div>
      <div class="row mt-20">
        <div class="icon-wheel col-2"></div>
        <h3 class="col-10"><span i18n>Ruote: 20"</span></h3>
      </div>
      <div class="row mt-20">
        <div class="icon-battery col-2"></div>
        <h3 class="col-10"><span i18n>Autonomia: fino a 95KM</span></h3>
      </div>
      <div class="row mt-20">
        <div class="icon-gear col-2"></div>
        <h3 class="col-10"><span i18n>Cambio a 7 rapporti</span></h3>
      </div>
      <div class="row mt-20">
        <div class="icon-brake col-2"></div>
        <h3 class="col-10"><span i18n>Freni a disco idraulici</span></h3>
      </div>
      <div class="row mt-20">
        <div class="icon-weight col-2"></div>
        <h3 class="col-10"><span i18n>Peso: 30kg</span></h3>
      </div>
      <div class="row mt-20">
        <div class="icon-engine col-2"></div>
        <h3 class="col-10"><span i18n>Motore: 250W 48V</span></h3>
      </div>
    </div>
  </div>
  <div class="row">
    <div
      class="col-md-6 col-sm-12 image-bike2"
      data-aos="fade-in"
      data-aos-duration="1000"
    ></div>
    <div
      class="col-md-6 col-sm-12 text-security-box"
      data-aos="slide-left"
      data-aos-duration="1000"
    >
      <h1 class="title-main accent-text">travel cross</h1>
      <div class="row mt-30">
        <div class="icon-frame col-2"></div>
        <h3 class="col-10">
          <span i18n>Telaio: Alluminio Idroformato</span>
        </h3>
      </div>
      <div class="row mt-20">
        <div class="icon-wheel col-2"></div>
        <h3 class="col-10"><span i18n>Ruote: 29"</span></h3>
      </div>
      <div class="row mt-20">
        <div class="icon-battery col-2"></div>
        <h3 class="col-10"><span i18n>Autonomia: fino a 90KM</span></h3>
      </div>
      <div class="row mt-20">
        <div class="icon-gear col-2"></div>
        <h3 class="col-10"><span i18n>Cambio a 10 rapporti</span></h3>
      </div>
      <div class="row mt-20">
        <div class="icon-brake col-2"></div>
        <h3 class="col-10"><span i18n>Freni a disco idraulici</span></h3>
      </div>
      <div class="row mt-20">
        <div class="icon-weight col-2"></div>
        <h3 class="col-10"><span i18n>Peso: 26,5kg</span></h3>
      </div>
      <div class="row mt-20">
        <div class="icon-engine col-2"></div>
        <h3 class="col-10"><span i18n>Motore: 250W 36V</span></h3>
      </div>
    </div>
  </div>
  <div class="row">
    <div
      class="col-md-6 col-sm-12 image-bike3"
      data-aos="fade-in"
      data-aos-duration="1000"
    ></div>
    <div
      class="col-md-6 col-sm-12 text-security-box"
      data-aos="slide-left"
      data-aos-duration="1000"
    >
      <h1 class="title-main accent-text">urban bike icon</h1>
      <div class="row mt-30">
        <div class="icon-frame col-2"></div>
        <h3 class="col-10">
          <span i18n>Telaio: Alluminio Idroformato</span>
        </h3>
      </div>
      <div class="row mt-20">
        <div class="icon-wheel col-2"></div>
        <h3 class="col-10"><span i18n>Ruote: 28"</span></h3>
      </div>
      <div class="row mt-20">
        <div class="icon-battery col-2"></div>
        <h3 class="col-10"><span i18n>Autonomia: fino a 120KM</span></h3>
      </div>
      <div class="row mt-20">
        <div class="icon-gear col-2"></div>
        <h3 class="col-10"><span i18n>Cambio a 8 rapporti</span></h3>
      </div>
      <div class="row mt-20">
        <div class="icon-brake col-2"></div>
        <h3 class="col-10"><span i18n>Freni a disco idraulici</span></h3>
      </div>
      <div class="row mt-20">
        <div class="icon-weight col-2"></div>
        <h3 class="col-10"><span i18n>Peso: 27kg</span></h3>
      </div>
      <div class="row mt-20">
        <div class="icon-engine col-2"></div>
        <h3 class="col-10"><span i18n>Motore: 250W 48V</span></h3>
      </div>
    </div>
  </div>
  <div class="row">
    <div
      class="col-md-6 col-sm-12 image-bike4"
      data-aos="fade-in"
      data-aos-duration="1000"
    ></div>
    <div
      class="col-md-6 col-sm-12 text-security-box"
      data-aos="slide-left"
      data-aos-duration="1000"
    >
      <h1 class="title-main accent-text">appia</h1>
      <div class="row mt-30">
        <div class="icon-frame col-2"></div>
        <h3 class="col-10">
          <span i18n>Telaio: Alluminio</span>
        </h3>
      </div>
      <div class="row mt-20">
        <div class="icon-wheel col-2"></div>
        <h3 class="col-10"><span i18n>Ruote: 20"</span></h3>
      </div>
      <div class="row mt-20">
        <div class="icon-battery col-2"></div>
        <h3 class="col-10"><span i18n>Autonomia: fino a 95KM</span></h3>
      </div>
      <div class="row mt-20">
        <div class="icon-gear col-2"></div>
        <h3 class="col-10"><span i18n>Cambio a 7 rapporti</span></h3>
      </div>
      <div class="row mt-20">
        <div class="icon-brake col-2"></div>
        <h3 class="col-10"><span i18n>Freni a disco idraulici</span></h3>
      </div>
      <div class="row mt-20">
        <div class="icon-weight col-2"></div>
        <h3 class="col-10"><span i18n>Peso: 30kg</span></h3>
      </div>
      <div class="row mt-20">
        <div class="icon-engine col-2"></div>
        <h3 class="col-10"><span i18n>Motore: 250W 48V</span></h3>
      </div>
    </div>
  </div>
</div>
