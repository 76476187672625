<div id="presentation" style="background-color: #212121; padding-top: 80px">
  <div
    class="hero"
    data-aos="fade-in"
    data-aos-duration="3000"
    data-aos-offset="300"
  >
    <div
      class="logo"
      data-aos="fade-left"
      data-aos-duration="3000"
      data-aos-offset="300"
    >
      <div
        class="text1"
        data-aos="fade-down"
        data-aos-duration="3000"
        data-aos-offset="300"
      ></div>
    </div>
  </div>
</div>
